import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout
    style={{
      fontFamily: `Brandon Text`,
      fontWeight: 400,
      lineHeight: 1.4,
      color: `#393939`,
    }}
  >
    <SEO
      title="Resume"
      keywords={[`resume`, `application`, `gatsby`, `thomasliu`]}
    />

    <section className="row">
      <div className="col-3">
        <div className="background-info">
          <h2>Contact</h2>
          <p>
            <a href="mailto:thomasl@hey.com">thomasl@hey.com</a>
          </p>
          <p>
            <a href="tel:2133080159">(213)308-0159</a>
          </p>
          <p>Los Angeles, CA</p>
          <p>
            <a
              href="https://resume.thomasliu.com"
              target="__blank"
              rel="noopener"
            >
              resume.thomasliu.com
            </a>
          </p>
        </div>
        <div className="background-info">
          <h2>Education</h2>
          <p>UC Irvine</p>
          <p> B.S. Computer Science </p>
          <p> 2016</p>
        </div>
        <div className="background-info">
          <h2>Awards</h2>
          <p>
            HackUCI Spring 2014 <br />
            Best Hack using the Rdio API <br />
            Top 10 award
          </p>
        </div>
      </div>
      <div className="col-9 experience">
        <section>
          <h2>Experience</h2>
          <div className="experience__details">
            <h3>REI (Recreational Equipment Inc) - Software Engineer</h3>
            <p>June 2022 - Current</p>
          </div>
          <div className="experience__descriptions">
            <ul>
              <li>
                Joined as a founding member of the cycle team. Designed new
                front-end components on top of a microsite that revamped the
                customers' bike finding experience.
              </li>
              <li>
                Contributed to Cedar, the open-source component design system of
                REI, through new feature work that required cross-collaboration.
              </li>
              <li>
                Delivered key components of the PDP (Product Details Page)
                strategic vision such as shop the look, EADs (estimated arrival
                dates), and the store location finder.
              </li>
              <li>
                Worked with product management and the analytics team to
                implement A/B tests for new features which improved annualized
                contribution margins and surpassed yearly goals.
              </li>
              <li>
                Modernized the application by migrating the add-to-cart service
                call from monolith to microservice.
              </li>
              <li>
                Revamped the customer experience of the media center by
                transforming the legacy layout and integrating additional
                contextual UGC (user-generated content) data.
              </li>
              <li>
                Technologies: Vue.js, Typescript, Vite, Vitest, Vue Test Utils,
                Java, SpringBoot, Maven, Figma, Cedar Design system, Jira,
                Confluence, Jenkins, BitBucket, Gitlab, Akamai, Datadog, AWS
              </li>
            </ul>
          </div>
        </section>
        <section>
          <div className="experience__details">
            <h3>Alltrue (formerly CauseBox) - Software Engineer</h3>
            <p>Oct 2021 - Apr 2022</p>
          </div>
          <div className="experience__descriptions">
            <ul>
              <li>
                Developed and shipped user-facing features using component-based
                UI with Vue.js.
              </li>
              <li>
                Developed using best practices in front-end architecture,
                including performance, security and usability.
              </li>
              <li>
                Worked with cross-functional teams. Collaborating closely with
                designers, engineers, and product managers.
              </li>
              <li>
                Identified and resolved performance and scalability issues.
              </li>
              <li>
                Delivered features within tight deadlines and iterated on old
                and new features.
              </li>
              <li>Utilized responsive and cross-browser/platform testing.</li>
              <li>
                Collaborated with developers on design techniques and coding
                standards.
              </li>
              <li>
                Technologies: Vue.js, Webpack, ES6, Cypress.js, Storybook.js,
                HTML/CSS, PHP, MySQL, Figma, Docker, CGP, Shortcut, Github,
                Cloudflare, Datadog
              </li>
            </ul>
          </div>
        </section>
        <section>
          <div className="experience__details">
            <h3>Brandastic - Developer</h3>
            <p>Jun 2017 - Oct 2021</p>
          </div>
          <div className="experience__descriptions">
            <ul>
              <li>
                Developed cross-browser and responsive UI applications with
                React, WordPress, and PHP.
              </li>
              <li>
                Managed and supported production environments for system
                reliability and uptime.
              </li>
              <li>
                Mentor and train other team members on design techniques and
                coding standards.
              </li>
              <li>
                Automated application deployments. Reduced risk and time
                required to deploy to production.
              </li>
              <li>
                Worked closely with customers and the project management team to
                gather requirements, create timeline estimates, and keep
                projects on track.
              </li>
              <li>
                Technologies: React.js, Webpack, Gulp, ES6, jQuery, REST, Styled
                Components, HTML/SCSS/CSS, PHP, MySQL, Apache, Ubuntu, CentOS,
                Linode, JIRA, Bitbucket, Cloudflare, Confluence, Deploybot
              </li>
            </ul>
          </div>
        </section>
        {/* <section>
          <h2>Projects</h2>
          <div className="experience__details">
            <h3>Static site generators and server side rendering</h3>
            <p>2019</p>
          </div>
          <div className="experience__descriptions">
            <ul>
              <li>
                - Experimented with Gatsby.js as a static site generator (SSG).
                <br />
                - Assessed the state of SSR (server side rendering via
                javascript), link prefetching, image optimizations, and site
                speed.
                <br />- This resume is built with Gatsby.js and hosted on
                Netlify. It is mobile friendly and print friendly.
              </li>
              <li>Technologies: Gatbsy.js, GraphQL, React.js, Netlify</li>
            </ul>
          </div>

          <div className="experience__details">
            <h3>Microservices and API's</h3>
            <p>2016</p>
          </div>
          <div className="experience__descriptions">
            <ul>
              <li>
                - Created microservices and API's: An image search abstraction
                layer powered by Bing’s image search API, a URL shortening
                service API, a request header parser API, and a timestamp
                microservice API.
              </li>
              <li>Technologies: Node.js, Express.js, MongoDB, Mongoose</li>
            </ul>
          </div>

          <div className="experience__details">
            <h3>Full stack IMDB clone (Fabflix)</h3>
            <p>2016</p>
          </div>
          <div className="experience__descriptions">
            <ul>
              <li>
                - Created a full stack IMDB website clone called Fabflix.
                Implemented features for user login validation and
                authentication, search and filtering, and user session handling.
                The app was then deployed onto AWS via EC2 and RDS with MySQL as
                the database.
              </li>
              <li>
                Technologies: AWS (EC2, RDS, Route53), Java, Java Servlets/JSP,
                JDBC/MySQL, Tomcat Apache
              </li>
            </ul>
          </div>

          <div className="experience__details">
            <h3>Mood Music - HackUCI</h3>
            <p>2014</p>
          </div>
          <div className="experience__descriptions">
            <ul>
              <li>
                - Mood Music started off as an ambitious idea to provide web
                users with content-relevant music that reflects the mood of the
                websites they visit.
                <br />- Combined text extraction, natural language processing to
                determine mood, and integrated with the Rdio API to create a
                Firefox add-on.
              </li>
              <li>Technologies: Javascript, HTML/CSS, Python</li>
            </ul>
          </div>
        </section> */}
      </div>
    </section>
  </Layout>
)

export default IndexPage
